
import TitleDescriptionUniversal from '@/components/portal/TitleDescriptionUniversal.vue';
import { actualUrl } from '@/api/instance';
import { getAuthorizationKeyForWebComponents } from '@/api/ssoAuthorization';
import LazyLoadWebComponent from '@/components/lazyload/LazyLoadWebComponent.vue';
import { Component, Vue } from 'vue-property-decorator';
import IconArrow from '@/components/icons/IconArrow.vue';
import { finishFlow, startFlow } from '@/api/flowSession';
import { goToDashBoard } from '@/utils/redirectUtils';

@Component({
  components: {
    TitleDescriptionUniversal,
    LazyLoadWebComponent,
    IconArrow,
  },
})
export default class BusinessValueResultsPage extends Vue {
  actualUrl = actualUrl();
  ssoKey = '';
  isShowErrorMessage = false;
  appsKpi = [];
  appsValuation = [];
  timeOut = 0;
  isSubscribeSessionSharesActive = true;

  get authObject(): any {
    return JSON.stringify({
      email: this.$store.state.user.email,
      key: this.ssoKey,
    });
  }

  async goToPortal(): Promise<void> {
    await finishFlow();
    goToDashBoard('dashboard')
    // this.$router.push({ name: 'PortalPage' });
  }
  async getSsoKey(): Promise<any> {
    try {
      this.ssoKey = ((await getAuthorizationKeyForWebComponents()) as any).data;
    } catch (error) {
      this.isShowErrorMessage = true;
    }
  }

  async startFlow(): Promise<void> {
    await startFlow('508')
  }

  mounted(): void {
    this.startFlow();
    this.getSsoKey();
  }

  destroyed(): void {
    this.isSubscribeSessionSharesActive = false;
  }
}
